import React from "react";

export default function CreateUpdateApi() {
  const newKey = "example_key";
  const newValue = "example_value";

  return (
    <div>
      <h2 className="text-2xl font-semibold mt-10 pt-8 border-t">
        Create Update Tags API
      </h2>
      <p className="text-gray-600 mt-2">
        To create a new tag, click on the "New Tag" button in tags table button.
        <br />
        <b>Developer: Amit</b>
      </p>
      <div className="bg-gray-800 p-6 rounded-md mt-5">
        <div>
          <p className="font-bold text-white">API:</p>
          <a
            target="_blank"
            rel="noreferrer"
            className="apiLink text-blue-400"
            href={`https://api15.ecommcube.com/_${"[AmitServerId]"}apidata/long_jtags_update`}
          >
            <code className="text-green-300">
              http POST https://api15.ecommcube.com/_{"[AmitServerId]"}
              apidata/long_jtags_update
            </code>
          </a>
        </div>
        <div className="mt-4">
          <b className="text-white">## Request Body</b>
          <pre className="text-gray-200 border border-white/50 rounded-md p-4 bg-gray-900 mt-3">
            {`{
  "${newKey}": "${newValue}",
  "pass": "billy"
}`}
          </pre>
        </div>
        <div className="mt-4">
          <b className="text-white">## Query Parameters</b>
          <ul className="list-disc pl-6 text-gray-400">
            <li>
              {" "}
              - `{"[AmitServerId]"}` (string): Replace this with the website id
              from amit's server.
            </li>
            <li>
              - `pass` (string): Authentication passkey for accessing the API.
            </li>
            <li>
              - `newKey` (string): The key for the data you want to update.
            </li>
            <li>
              - `newValue` (string): The new value to set for the specified key.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
