import React from "react";
import Sidebar from "../../components/Menu/Sidebar";

export default function Layout({ children, show_navlabel, toggleNavbar }) {
  return (
    <div
      className={
        show_navlabel
          ? "grid grid-cols-body min-h-screen"
          : "grid grid-cols-bodyClosed min-h-screen"
      }
    >
      <Sidebar show_navlabel={show_navlabel} toggleNavbar={toggleNavbar} />
      <div
        className={
          show_navlabel
            ? "bg-gray-100 h-screen overflow-y-scroll"
            : "bg-gray-100 overflow-hidden"
        }
      >
        {children}
      </div>
    </div>
  );
}
