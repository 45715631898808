import {
  Layout,
  Dashboard,
  Login,
  Websites,
  Images,
  Docs,
  Blogs,
  HowTo,
  FAQs,
  Services,
  Video,
  Footer,
  StateText,
  CountyText,
  CityText,
  ZipText,
  Companies,
  Inbox,
  Quotes,
} from "../pages";
import Zips from "../pages/Zips";
import Zip from "../pages/Zips/Zip";

export const routes = [
  {
    path: "/",
    element: Dashboard,
  },
  {
    path: "/dashboard",
    element: Dashboard,
  },
  {
    path: "/:website",
    element: Websites,
  },
  {
    path: "/:website/images",
    element: Images,
  },
  {
    path: "/:website/blogs",
    element: Blogs,
  },
  {
    path: "/:website/services",
    element: Services,
  },
  {
    path: "/:website/video",
    element: Video,
  },
  {
    path: "/:website/state-text",
    element: StateText,
  },
  {
    path: "/:website/county-text",
    element: CountyText,
  },
  {
    path: "/:website/city-text",
    element: CityText,
  },
  {
    path: "/:website/zip-text",
    element: ZipText,
  },
  {
    path: "/:website/howto",
    element: HowTo,
  },
  {
    path: "/:website/footer",
    element: Footer,
  },
  {
    path: "/:website/faqs",
    element: FAQs,
  },
  {
    path: "/:website/companies",
    element: Companies,
  },
  {
    path: "/:website/inbox",
    element: Inbox,
  },
  {
    path: "/:website/quotes",
    element: Quotes,
  },
  {
    path: "/:website/zips",
    element: Zips,
  },
  {
    path: "/:website/zips/:zip",
    element: Zip,
  },
];
