import { Menu, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { tagsType } from "./Menu/Menu";

function EditGuide() {
  return (
    <Menu as="div" className="relative">
      <Menu.Button title="Text Editor Guide" className="flex items-center justify-center">
        <InformationCircleIcon className="w-7" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 top-0 mt-7 bg-white p-5 z-20 shadow-xl shadow-gray-300 rounded-lg w-80">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 shadow-sm ">
              <tr>
                <th scope="col" className="tableHead">
                  Tag
                </th>
                <th scope="col" colSpan={2} className="tableHead">
                  Description
                </th>
              </tr>
            </thead>
            <tbody className="bg-white text-gray-900 divide-y divide-gray-200 h-full overflow-y-scroll">
              {tagsType.map((tag, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-3 py-2">
                    <div className="text-sm ">{tag.name}</div>
                  </td>
                  <td colSpan={2} className="p-3 py-2">
                    <div className="text-sm">{tag.des}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default EditGuide;
