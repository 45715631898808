import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import LayoutHead from "../Layout/LayoutHead";
import { websites } from "../../components/Menu/Menu";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, CheckBox, SearchBox } from "../../components";
import {
  ChevronLeft,
  ChevronRight,
  Globe,
  LoaderCircle,
  Menu,
  ShieldAlertIcon,
  Trash,
} from "lucide-react";

// Custom Hook for Data Fetching
const useFetchZips = (website) => {
  const [zipsList, setZipsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchZips = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${
            website?.includes("localhost") ? "http://" : "https://www."
          }${website}/api/zips`
        );
        setZipsList(data || []);
      } catch (error) {
        setError("Error fetching zips. Please try again later.");
        console.error("Error fetching zips:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchZips();
  }, [website]);

  return { zipsList, loading, error };
};

export default function Zips({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const { zipsList, loading, error } = useFetchZips(website);
  const [displayedZips, setDisplayedZips] = useState([]);
  const [activeStatus, setActiveStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [select, setSelect] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1000);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [action, setAction] = useState(false);

  const deleteAllZips = async () => {
    setAction(true);
    try {
      const res = await axios.post(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/delete_zips`,
        {
          delete_all: 1,
          status:
            activeStatus && activeStatus !== "not_opened" ? activeStatus : null,
        }
      );
      if (res.status === 200) {
        setDisplayedZips([]);
        setDeleteConfirmation(null);
      }
    } catch (error) {
      console.error("Error fetching zips:", error);
    }
  };
  const deleteSelected = async () => {
    setAction(true);
    try {
      const res = await axios.post(
        `${
          website?.includes("localhost") ? "http://" : "https://www."
        }${website}/api/delete_zips`,
        {
          zipCodes: selectedData,
          status:
            activeStatus && activeStatus !== "not_opened" ? activeStatus : null,
        }
      );
      if (res.status === 200) {
        const result = displayedZips.filter(
          (code) => !selectedData.includes(code)
        );
        setSelectedData([]);
        setDisplayedZips(result);
        setSelect(false);
        setDeleteConfirmation(null);
      }
    } catch (error) {
      console.error("Error fetching zips:", error);
    }
    setDeleteConfirmation(null);
  };
  // Set the initial displayed zips when zipsList is fetched
  useEffect(() => {
    setDisplayedZips(zipsList.zipCodes || []);
  }, [zipsList]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredZips = useMemo(() => {
    return displayedZips?.filter((zip) =>
      zip.includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, displayedZips]);

  const paginatedZips = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredZips?.slice(startIndex, endIndex);
  }, [filteredZips, currentPage, itemsPerPage]);

  const totalPages = useMemo(
    () => Math.ceil(filteredZips?.length / itemsPerPage),
    [filteredZips, itemsPerPage]
  );

  const isSelected = (zip) => selectedData.includes(zip);

  const handleSelectAll = (checked) => {
    setSelectedData(checked ? displayedZips : []);
  };

  const handleSingleSelect = ({ checked, data }) => {
    setSelectedData((prevSelected) =>
      checked
        ? [...prevSelected, data]
        : prevSelected.filter((item) => item !== data)
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleStatusClick = (status) => {
    setDisplayedZips(zipsList.zip_status[status] || []);
    setActiveStatus(status);
    setSearchQuery("");
    setCurrentPage(1);
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <div>
      <LayoutHead website={site}>
        {/* Header with Navigation */}
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Menu className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}
          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <Globe className="text-gray-700 w-6 h-6" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="px-7 pt-5">
        {/* Breadcrumbs and Filters */}
        <div className="flex items-center justify-between">
          <Breadcrumbs />
          {zipsList?.zipCodes ? (
            <div className="flex items-center justify-end gap-2 flex-1">
              <button
                onClick={() => {
                  setDisplayedZips(zipsList?.zipCodes);
                  setActiveStatus(null); // Reset active status
                }}
                className={`btnWhite px-4 whitespace-nowrap capitalize ${
                  activeStatus === null ? "bg-secondary text-white" : ""
                }`}
              >
                Files Created {`(${zipsList?.zipCodes?.length})`}
              </button>
              {Object.keys(zipsList.zip_status || {})?.map((status) => (
                <button
                  key={status}
                  onClick={() => handleStatusClick(status)}
                  className={`btnWhite px-4 whitespace-nowrap capitalize ${
                    activeStatus === status ? "bg-secondary text-white" : ""
                  }`}
                >
                  {`${status?.replace("_", " ")}  (${
                    zipsList.zip_status[status]?.length
                  })`}
                </button>
              ))}
            </div>
          ) : (
            <p className="whitespace-nowrap">Loading Filters...</p>
          )}
        </div>
        {/* Search and Selection Controls */}
        <div className="flex items-center justify-between my-4">
          <div className="flex items-center gap-6">
            <h2 className="elementHeading3">Zips List</h2>
            <SearchBox
              value={searchQuery}
              onChange={handleSearchChange}
              className="bg-white"
              placeholder="Search zip"
            />
          </div>
          {activeStatus !== "not_opened" ? (
            <div className="flex items-center gap-2">
              {select ? (
                <div className="flex items-center justify-center gap-2">
                  <button
                    className="btnPrimary bg-red-500 py-2 text-sm"
                    onClick={() => setDeleteConfirmation("selected")}
                  >
                    Delete Selected
                  </button>
                  <CheckBox
                    className="btnWhite rounded-md py-2 px-4"
                    label="Select All"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={
                      zipsList?.length &&
                      zipsList.length === selectedData.length
                    }
                  />
                  <button
                    className="btnWhite rounded-md py-2 px-4 bg-red-100 text-red-700"
                    onClick={() => setSelect(false)}
                  >
                    Cancel Select
                  </button>
                </div>
              ) : (
                <>
                  <button
                    className="btnPrimary bg-red-500 py-2 px-3 text-sm"
                    onClick={() => setDeleteConfirmation("all")}
                  >
                    <Trash className="w-4 h-4" />
                    Delete All
                  </button>
                  <button
                    className="btnPrimary py-2 text-sm"
                    onClick={() => setSelect(true)}
                  >
                    Select Zips
                  </button>
                </>
              )}
            </div>
          ) : null}
        </div>

        {/* Zips Display */}
        <div className="mt-4 h-[calc(100vh-260px)] overflow-y-scroll">
          {loading ? (
            <div className="flex items-center justify-center gap-8 mt-[350px] flex-col">
              <LoaderCircle className="w-16 h-16 animate-spin text-secondary" />
              <p className="flex items-center justify-center text-gray-500">
                Loading Zips List...
              </p>
            </div>
          ) : error ? (
            <p className="text-center w-full text-lg text-red-500">{error}</p>
          ) : (
            <div className="flex flex-wrap">
              {paginatedZips?.length > 0 ? (
                paginatedZips.map((zip) =>
                  select ? (
                    <button key={zip}>
                      <CheckBox
                        checked={isSelected(zip)}
                        className="px-4 py-1 hover:bg-white hover:shadow-md transition-all rounded-full cursor-pointer flex items-center"
                        onChange={(e) =>
                          handleSingleSelect({
                            checked: e.target.checked,
                            data: zip,
                          })
                        }
                        label={zip}
                      />
                    </button>
                  ) : (
                    <Link
                      to={`/${site.domain}/zips/${zip}`}
                      key={zip}
                      className="px-4 py-1 hover:bg-secondary hover:text-white hover:shadow-md transition-all rounded-full cursor-pointer"
                    >
                      {zip}
                    </Link>
                  )
                )
              ) : (
                <p className="text-center w-full text-lg text-gray-500">
                  No Zips Found
                </p>
              )}
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="flex items-center justify-between pt-6 border-t">
          <p>
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(currentPage * itemsPerPage, filteredZips?.length || 0)} of{" "}
            {filteredZips?.length || 0} zips
          </p>
          <div className="flex items-center justify-end">
            <div className="flex justify-end mr-10">
              <label htmlFor="itemsPerPage" className="mr-2">
                Items per page:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="border border-gray-300 rounded px-2 py-1"
              >
                {[100, 300, 600, 1000, 2000, 5000, 10000].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center justify-end gap-2">
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="btnWhite h-8 w-8 hover:bg-secondary hover:text-white transition-all"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft className="w-7 h-7" />
              </button>
              <button
                className="btnWhite h-8 w-8 hover:bg-secondary hover:text-white transition-all"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ChevronRight className="w-7 h-7" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {deleteConfirmation && (
        <div className="fixed top-0 left-0 h-screen w-full flex items-center justify-center bg-gray-600 z-50 bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 dark:text-white px-10 py-6 rounded-xl flex flex-col items-center text-center">
            <ShieldAlertIcon className="h-12 w-14 text-yellow-400 mb-3" />
            <p className="text-lg">
              Are you sure? You want to delete all the vendor files.
            </p>
            <div className="flex items-center justify-center gap-6 mt-6">
              <button onClick={() => setDeleteConfirmation(null)}>
                Cancel
              </button>
              <button
                onClick={
                  deleteConfirmation === "selected"
                    ? deleteSelected
                    : deleteAllZips
                }
                className="btnPrimary bg-red-500"
              >
                {action ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {action ? <p>Deleting</p> : <p>Delete</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
