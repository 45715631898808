// store.js

import { configureStore } from '@reduxjs/toolkit';
import mySliceReducer from './mySlice';

const store = configureStore({
  reducer: {
    mySlice: mySliceReducer,
  },
});

export default store;
