export const images = [
  {
    title: "Favicon 16x16",
    address: `favicon-16x16.png`,
  },
  {
    title: "Favicon 32x32",
    address: `favicon-32x32.png`,
  },
  {
    title: "Favicon 192x192",
    address: `android-chrome-192x192.png`,
  },
  {
    title: "Favicon 512x512",
    address: `android-chrome-512x512.png`,
  },
  {
    title: "Banner",
    address: `img/banner.webp`,
  },
  {
    title: "Service 1",
    address: `img/1.webp`,
  },
  {
    title: "Service 2",
    address: `img/2.webp`,
  },
  {
    title: "Service 3",
    address: `img/3.webp`,
  },
  {
    title: "Service 4",
    address: `img/4.webp`,
  },
  {
    title: "Service 5",
    address: `img/5.webp`,
  },
  {
    title: "Contact Card",
    address: `img/contact-background.webp`,
  },
  {
    title: "industry image",
    address: `img/truck.webp`,
  },
];
