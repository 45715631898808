import React from "react";
import Header from "./Header";
import DocsBody from "./DocsBody";
import LeftBar from "./LeftBar";
import RightBar from "./RightBar";

export default function Docs() {
  return (
    <div className="bg-white">
      <Header />
      <div className="flex items-center justify-center flex-col">
        <div className="w-10/12 flex items-center justify-center flex-col">
          <div className="grid grid-cols-docs gap-16 mt-10">
            <div className="sticky left-0 top-0">
              <LeftBar />
            </div>
            <DocsBody />
            <div className="sticky right-0 top-0">
              <RightBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
