import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function SearchBox({ className, value, onChange, placeholder }) {
  return (
    <div className={`relative ${className} border rounded`}>
      <div className="flex items-center w-full appearance-none px-3 flex-1">
        <MagnifyingGlassIcon className="w-5" />
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
        />
      </div>
    </div>
  );
}
