import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { websites } from "./Menu";
import { useDispatch } from "react-redux";
import { onLogOut, setPassword } from "../../store/mySlice";
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/solid";

const Sidebar = ({ toggleNavbar, show_navlabel }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (item) => {
    navigate(`/${item.domain}`);
  };

  return (
    <div className="p-3 bg-white dark:bg-blue dark:text-white/80 shadow-r dark:shadow-gray-800">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex justify-between items-center mb-5 pt-1 px-2">
            <Link to="/" className="text-2xl font-bold">
              Dashboard
            </Link>
            {show_navlabel && (
              <button type="button" onClick={toggleNavbar}>
                <Bars3Icon className="cursor-pointer w-6 dark:text-white" />
              </button>
            )}
          </div>
          {websites.map((item) => (
            <button
              key={item.id}
              className={`pageLink hover:bg-gray-200 hover:pl-5 transition-all py-1 capitalize border-b hover:rounded-lg ${
                currentPath.startsWith(`/${item.domain}`) &&
                "bg-secondary text-white hover:text-white hover:bg-secondary pl-4 rounded-lg"
              }`}
              title={"id = " + item.id + " " + item.name}
              onClick={() => handleClick(item)}
            >
              {currentPath.startsWith(`/${item.domain}`) && (
                <PaperAirplaneIcon className="w-3 mr-3" />
              )}
              {item.name}
            </button>
          ))}
        </div>

        <div>
          <button
            onClick={() => dispatch(onLogOut())}
            className="pageLink gap-2 text-red-500"
          >
            <ArrowRightOnRectangleIcon className="w-5" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
