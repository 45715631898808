import React from "react";

export default function RightBar() {
  return (
    <div className="sticky top-28 right-0">
      <p className="text-lg font-medium">On this page</p>
      <p>Introduction</p>
      <p>Main Features</p>
    </div>
  );
}
