import React from "react";

const Checkbox = React.memo(
  ({
    label = "",
    checked,
    onChange,
    className = "",
    boxStyle = "",
    disabled = false,
    boxSize = "h-3 w-3",
  }) => {
    return (
      <label
        className={`flex items-center gap-2 ${
          disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        } ${className}`}
      >
        <input
          disabled={disabled}
          type="checkbox"
          className={`form-checkbox text-secondary cursor-pointer disabled:cursor-not-allowed ${boxStyle} ${boxSize}`}
          checked={checked}
          onChange={onChange}
        />
        <span>{label}</span>
      </label>
    );
  }
);

export default Checkbox;
