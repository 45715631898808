import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes } from "./routes";
import { Docs, Layout, Login } from "../pages";

export default function Router() {
  const password = useSelector((state) => state.mySlice.password);
  const isLoggedIn = useSelector((state) => state.mySlice.isLoggedIn);
  const [show_navlabel, setShowNavbar] = useState(true);
  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };
  let static_password = "billi@billy@AAA";

  return (
    <BrowserRouter>
      {isLoggedIn ? (
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout
                  show_navlabel={show_navlabel}
                  toggleNavbar={toggleNavbar}
                >
                  <route.element
                    show_navlabel={show_navlabel}
                    toggleNavbar={toggleNavbar}
                  />
                </Layout>
              }
            />
          ))}
          <Route path="/docs" element={<Docs />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
