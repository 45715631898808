import React, { useEffect, useState } from "react";
import LayoutHead from "./Layout/LayoutHead";
import { Link, useParams } from "react-router-dom";
import { Bars3BottomLeftIcon, PencilIcon } from "@heroicons/react/24/solid";
import { websites } from "../components/Menu/Menu";
import axios from "axios";
import MarkdownConverter from "./Websites/MarkdownConverter";
import { toast } from "react-toastify";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

export default function StateText({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [editText, setEditText] = useState("");

  const getText1 = () => {
    const tagName = "state_text_1";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setText1(res.data.text))
      .catch((err) => console.log(err));
  };

  const getText2 = () => {
    const tagName = "state_text_2";
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setText2(res.data.text))
      .catch((err) => console.log(err));
  };

  const [states, setStates] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api15.ecommcube.com/_${site.id}apidata/states?pass=billy`)
      .then((res) => setStates(res.data.list))
      .catch((err) => console.log(err));
    getText1();
    getText2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateText1 = (event) => {
    event.preventDefault();
    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            state_text_1: text1,
            pass: "billy",
          },
        }
      )
      .then(() => {
        toast.success("Data Updated");
        setEditText(false);
        // Update the state directly
        setText1(text1);
      })
      .catch((err) => toast.error(err));
  };

  const UpdateText2 = (event) => {
    event.preventDefault();
    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            state_text_2: text2,
            pass: "billy",
          },
        }
      )
      .then(() => {
        toast.success("Data Updated");
        setEditText(false);
        // Update the state directly
        setText2(text2);
      })
      .catch((err) => toast.error(err));
  };

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="p-6">
        <h1 className="elementHeading2">{site.domain} serving in states</h1>
        <div className="grid grid-cols-2 lg:grid-cols-8 mt-5 w-full text-xs font-medium">
          {states.map((item, index) => (
            <button className="serviceButton" key={index}>
              {item}
            </button>
          ))}
        </div>
      </div>

      <div className="p-6">
        {/* Text 1 */}
        <div className="flex items-center gap-7">
          <h1 className="font-bold text-2xl">State Text 1</h1>
          <div className="flex items-center gap-3">
            {editText === "text1" ? (
              <>
                <button
                  type="button"
                  onClick={() => setEditText("")}
                  className="btnPrimary py-1 px-4 bg-red-500"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={UpdateText1}
                  className="btnPrimary py-1 px-4 bg-green-600"
                >
                  Save & Update
                </button>
              </>
            ) : (
              <button
                onClick={() => setEditText("text1")}
                type="button"
                className="btnPrimary py-1 px-4 font-medium"
              >
                <PencilIcon className="w-4" /> Edit
              </button>
            )}
          </div>
        </div>
        {editText === "text1" ? (
          <MarkdownConverter
            newValue={text1}
            setNewValue={setText1}
            height="h-[400px]"
            outputStyle="bg-white"
          />
        ) : (
          <div className="bg-white p-7 rounded-lg mt-3">{text1}</div>
        )}

        {/* Text 2 */}
        <div className="flex items-center gap-7 mt-7">
          <h1 className="font-bold text-2xl">State Text 2</h1>
          <div className="flex items-center gap-3">
            {editText === "text2" ? (
              <>
                <button
                  type="button"
                  onClick={() => setEditText("")}
                  className="btnPrimary py-1 px-4 bg-red-500"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={UpdateText2}
                  className="btnPrimary py-1 px-4 bg-green-600"
                >
                  Save & Update
                </button>
              </>
            ) : (
              <button
                onClick={() => setEditText("text2")}
                type="button"
                className="btnPrimary py-1 px-4 font-medium"
              >
                <PencilIcon className="w-4" /> Edit
              </button>
            )}
          </div>
        </div>
        {editText === "text2" ? (
          <MarkdownConverter
            newValue={text2}
            setNewValue={setText2}
            height="h-[400px]"
            outputStyle="bg-white"
          />
        ) : (
          <div className="bg-white p-7 rounded-lg mt-3">{text2}</div>
        )}
      </div>
    </div>
  );
}
