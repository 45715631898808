import React, { useEffect, useState } from "react";
import LayoutHead from "../Layout/LayoutHead";
import {
  ArrowPathIcon,
  Bars3BottomLeftIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import { vishnuIds, websites } from "../../components/Menu/Menu";
import axios from "axios";
import Company from "../Companies/Company";
import { DataTable } from "../../components";

const Inbox = ({ show_navlabel, toggleNavbar }) => {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const secondId = vishnuIds.find((site) => site.domain === website);

  const [inboxList, setInBoxList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const tag_name = "inbox_list";
  const getInboxList = () => {
    setIsLoading(true);
    axios
      .get(
        `https://${process.env.REACT_APP_API_ENDPOINT}/_${site.id}apidata/tagtext?tag=${tag_name}&pass=billy`
      )
      .then((res) => setInBoxList(JSON.parse(res.data.text)))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getInboxList();
  }, []);

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="px-7 pt-6">
        <div className="flex items-center justify-between mb-5">
          <div className="flex items-center">
            <h2 className="text-2xl font-bold">Companies Listing Requests </h2>
            <p className="px-3 h-7 rounded-full text-base ml-2 bg-secondary text-white flex items-center justify-center">
              {inboxList?.length}
            </p>
          </div>
          <button
            onClick={getInboxList}
            className="btnPrimary bg-black text-base text-white px-3 active:scale-105 transition-all"
          >
            <ArrowPathIcon className={`w-4 ${isLoading && "animate-spin"}`} />{" "}
            Refresh
          </button>
        </div>
        <DataTable
          tableHeight="h-[calc(100vh-220px)]"
          heads={[
            "Sr#",
            "Actions",
            "Company Name",
            "City Name",
            "Zip Code",
            "Address",
            "Phone",
            "Name",
            "Email",
          ]}
          items={inboxList?.map((item, index) => ({
            serailNumber: index + 1,
            actions: (
              <div className="flex justify-center gap-2 items-center">
                <button className="btnPrimary py-1 bg-red-600">Reject</button>
                <button className="btnPrimary py-1">Approve Listing</button>
              </div>
            ),
            companyName: item.companyName,
            companyPhone: item.companyPhone,
            cityName: item.cityName,
            zipCode: item.zipCode,
            location: item.address,
            name: item.name,
            email: item.email,
          }))}
        />
        {/*
        <div className="grid grid-cols-1 gap-8">
          {inboxList.map((item, index) => (
            <div key={index}>
              <Company
                companyName={item.companyName}
                phone={item.companyPhone}
                city={item.cityName}
                zip={item.zipCode}
                location={item.address}
                name={item.name}
                email={item.email}
                cardLocation="addCompany"
                className="lg:grid-cols-1 lg:gap-2"
              />
              <div className="flex justify-end mt-3 gap-2 items-center">
                <button className="btnPrimary bg-red-600">Reject</button>
                <button className="btnPrimary">Approve Listing</button>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Inbox;
