import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { websites } from "./Menu/Menu";
import {
  ArrowUpTrayIcon,
  CameraIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

function FileUploadForm({ blogName }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);

  const [directory, setDirectory] = useState(`${site.domain}/blogs`);
  const [selectedFile, setSelectedFile] = useState(null);
  const [remotePath, setRemotePath] = useState(blogName);
  const [password, setPassword] = useState("billy");
  const [imageSrc, setImageSrc] = useState("");

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const handleImageError = () => {
    setImageLoaded(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Create an object URL for the selected file
    const url = URL.createObjectURL(file);
    setImageSrc(url);
  };

  return (
    <div>
      <div
        style={{
          display: imageLoaded ? (selectedFile ? "none" : "flex") : "none",
        }}
        className="w-full bg-gray-100 rounded-lg overflow-hidden relative h-32 flex items-center justify-center mt-4"
      >
        <img
          src={`https://api15.ecommcube.com/${site.domain}/blogs/${blogName}`}
          alt="Selected Image"
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      </div>

      {selectedFile && (
        <div className="w-full bg-gray-100 rounded-lg overflow-hidden relative h-32 flex items-center justify-center mt-4">
          <img src={imageSrc} alt="Selected Image" />
        </div>
      )}

      <div className="flex items-center justify-between mb-3">
        <a
          target="_blank"
          href={`https://api15.ecommcube.com/${site.domain}/blogs/${blogName}`}
        >
          {blogName}
        </a>
        <form
          action="http://15.204.60.73:7001/upload"
          method="POST"
          enctype="multipart/form-data"
          target="_blank"
          className="flex items-center gap-3 justify-end mt-4"
        >
          <input
            className="hidden"
            type="text"
            id="directory"
            name="directory"
            value={`${site.domain}/blogs`}
            required
          />

          <label htmlFor="file" className="btnWhite text-black px-4 p-2 w-fit">
            {!selectedFile && "Blog Image"}
            <CameraIcon className="w-5 h-5" />
            <input
              type="file"
              id="file"
              name="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>

          <label className="hidden" for="remotePath">
            Remote File Name:
            <input
              type="text"
              id="remotePath"
              name="remotePath"
              value={blogName}
              required
            />
          </label>

          <input
            className="hidden"
            type="password"
            id="password"
            name="password"
            value="billy"
            required
          />
          {selectedFile && (
            <div className="bg-white cursor-pointer p-2 flex items-center justify-center rounded-full transition-all shadow-md w-9 h-9">
              <TrashIcon
                className="text-red-400"
                onClick={() => setSelectedFile(null)}
              />
            </div>
          )}

          <input
            disabled={!selectedFile}
            type="submit"
            className="btnPrimary font-medium text-sm"
            value="Upload Image"
          />
        </form>
      </div>

      {/* <form
        action="http://15.204.60.73:7001/delete"
        method="POST"
        onSubmit={handleSubmitDelete}
      >
        <label htmlFor="passwordDelete">Password:</label>
        <input
          type="password"
          id="passwordDelete"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input type="submit" value="Delete File" />
      </form> */}
    </div>
  );
}

export default FileUploadForm;
