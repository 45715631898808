import { Bars3BottomLeftIcon, DocumentIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import SearchBox from "./common/SearchBox";
import UserBox from "../pages/Layout/UserBox";

export default function DashboardNav({
  show_navlabel,
  toggleNavbar,
  searchQuery,
  handleSearchChange,
}) {
  return (
    <div
      className={`sticky top-0 z-40 flex items-center justify-between bg-white py-2 px-4 lg:px-6 gap-4 lg:gap-7 ${
        !show_navlabel && "fixed"
      }`}
    >
      {!show_navlabel && (
        <button
          type="button"
          onClick={toggleNavbar}
          className="flex items-center justify-center w-fit"
        >
          <Bars3BottomLeftIcon className="cursor-pointer w-6" />
        </button>
      )}
      <Link to="/" className="text-xl font-bold hidden md:block">
        National Sites Admin Panel
      </Link>
      <Link to={`/docs`}>
        <button type="button" className="btnWhite hover:bg-gray-100">
          <DocumentIcon className="w-4" />
          <p>Docs</p>
        </button>
      </Link>
      <SearchBox
        className="flex-1"
        placeholder="Search website by name, domain or id"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <UserBox />
    </div>
  );
}
