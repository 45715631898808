import React from "react";
import LayoutHead from "../Layout/LayoutHead";
import {
  Bars3BottomLeftIcon,
  ClipboardDocumentIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { SearchBox } from "../../components";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { websites } from "../../components/Menu/Menu";
import { useState } from "react";
import { useEffect } from "react";
import MarkdownIt from "markdown-it";
import MarkdownConverter from "../Websites/MarkdownConverter";
import { toast } from "react-toastify";

export default function Services({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [serviceText, setServiceText] = useState("");
  const [serviceTextMd, setServiceTextMd] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [textNumber, setTextNumber] = useState("text_1");
  const [editText, setEditText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const markdownIt = new MarkdownIt();

  const getServices = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/taglist?pattern=service_&pass=billy`
      )
      .then((res) => setServices(res.data.list))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceText = (item) => {
    setSelectedService(item);
    setServiceTextMd("");
    setIsLoading(true);
    axios
      .get(
        `https://api15.ecommcube.com/_${
          site.id
        }apidata/tagtext?tag=${item.replaceAll(
          " ",
          "_"
        )}_${textNumber}&pass=billy`
      )
      .then((res) => {
        setServiceTextMd(markdownIt.render(res.data.text));
        setServiceText(res.data.text);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (selectedService) {
      getServiceText(selectedService);
    }
  }, [selectedService, textNumber]);

  const UpdateServiceText = (event) => {
    event.preventDefault();

    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            [selectedService.replaceAll(" ", "_") + "_" + textNumber]:
              serviceText,
            pass: "billy",
          },
        }
      )
      .then(() => toast.success("Data Updated"))
      .catch((err) => toast.error(err));
    setEditText(false);
    getServiceText(selectedService);
  };

  const copySerivceText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Service text copied", { autoClose: 1000 });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              without
              rel="noreferrer"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>
      <div className="px-6 pt-6">
        {editText ? (
          <div className="grid grid-cols-2 gap-10">
            <div className="flex items-center justify-between">
              <h1 className="elementHeading2 capitalize">
                Edit {selectedService} {textNumber.replace("_", " ")}
              </h1>
              <ClipboardDocumentIcon
                title="copy service text"
                onClick={() => copySerivceText(serviceText)}
                className="w-7 cursor-pointer hover:opacity-80 text-gray-700"
              />
            </div>
            <div className="flex items-center justify-end gap-3">
              <button
                type="button"
                onClick={() => setEditText(false)}
                className="btnPrimary bg-red-500"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={UpdateServiceText}
                className="btnPrimary bg-green-600"
              >
                Save & Update
              </button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-7">
            <div className="flex items-center gap-4">
              <h1 className="elementHeading2 capitalize">
                {selectedService || "Services Texts"}
              </h1>
              <a
                title="Visit Website"
                target="_blank"
                without
                rel="noreferrer"
                href={`https://www.${site?.domain}/${selectedService.replaceAll(
                  " ",
                  "-"
                )}`}
                className="underline text-secondary"
              >
                {!selectedService || (
                  <ArrowTopRightOnSquareIcon className="w-7" />
                )}
              </a>
            </div>
            <div className="flex items-center gap-5">
              <button
                onClick={() => setEditText(true)}
                type="button"
                disabled={!serviceTextMd}
                className="font-bold flex items-center mt-3 text-lg gap-1 ml-1 disabled:cursor-not-allowed"
              >
                <PencilSquareIcon className="w-5" /> Edit
              </button>
              <select
                className="inputField w-fit cursor-pointer py-2 px-2"
                value={textNumber}
                onChange={(e) => setTextNumber(e.target.value)}
              >
                <option value="text_1">{selectedService} text 1</option>
                <option value="text_2">{selectedService} text 2</option>
              </select>
            </div>
          </div>
        )}
        {editText ? (
          <MarkdownConverter
            newValue={serviceText}
            setNewValue={setServiceText}
            height="h-[calc(100vh-220px)]"
            outputStyle="bg-white"
          />
        ) : (
          <div className="bg-white rounded-lg p-4 mt-3 grid grid-cols-2 gap-10">
            <div className="h-[calc(100vh-230px)] overflow-y-scroll">
              <SearchBox
                type="text"
                className="bg-white mb-4 mr-3 sticky top-0 shadow-md"
                placeholder="Search service"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {services
                ?.filter((item) =>
                  item.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((item, index) => (
                  <button
                    onClick={() => getServiceText(item)}
                    className={`text-sm w-fit mb-2 mr-2 rounded-full p-2 px-4 bg-gray-100 hover:shadow-lg hover:shadow-black/20 transition-all ${
                      item === selectedService && "bg-secondary text-white"
                    }`}
                    key={index}
                  >
                    {item}
                  </button>
                ))}
            </div>
            <div className="inputField min-h-fit overflow-y-scroll h-[calc(100vh-230px)] flex items-center justify-center">
              {serviceText === "" ? (
                <>
                  {selectedService ? (
                    <button
                      onClick={() => setEditText(true)}
                      className="border py-2 px-4 rounded capitalize border-gray-300"
                    >
                      Add {textNumber.replaceAll("_", " ")}
                    </button>
                  ) : (
                    "select a service"
                  )}
                </>
              ) : isLoading ? (
                <div className="flex flex-col text-xl font-semibold gap-5 items-center justify-center py-10 w-full">
                  <img src="/loading.gif" className="w-16" alt="" />
                  Loading service text
                </div>
              ) : (
                <div
                  className="markdown-content h-full w-full"
                  dangerouslySetInnerHTML={{ __html: serviceTextMd }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
