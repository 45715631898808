import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { websites } from "../../components/Menu/Menu";
import LayoutHead from "../Layout/LayoutHead";
import { Breadcrumbs, InputField } from "../../components";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { toast } from "react-toastify";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import LazyIframe from "../../components/LazyIframe";

export default function Video({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [videoURL, setVideoURl] = useState("");
  const tagName = "youtube_video";

  const getVideo = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=${tagName}&pass=billy`
      )
      .then((res) => setVideoURl(res.data.text))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVideo();
  }, []);

  const UpdateVideoURL = (event) => {
    event.preventDefault();

    // Make an API call to update the key-value pair on the server
    axios
      .post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            [tagName]: videoURL,
            pass: "billy",
          },
        }
      )
      .then(() => toast.success("Video URL updated"))
      .catch((err) => toast.error(err));
    getVideo();
  };

  return (
    <div>
      <LayoutHead>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="pt-7 px-7">
        <div className="flex items-center justify-between">
          <h1 className="elementHeading2">Youtube Video</h1>
          <div className="flex-1 flex justify-end gap-4 items-end">
            <InputField
              label="Key Name"
              placeholder="Enter key tag"
              value={tagName}
              disabled
              className="w-44"
            />
            <div className="w-96">
              <InputField
                label="Video URL"
                placeholder="Enter New Step Here and press add"
                value={videoURL}
                onChange={(event) => setVideoURl(event.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={UpdateVideoURL}
              className="btnPrimary bg-green-600"
            >
              Update
            </button>
          </div>
        </div>
        {videoURL ? (
          <LazyIframe
            className="w-full rounded-3xl h-96 lg:h-[400px] lg:border-b-2 border-black mt-7"
            url={videoURL}
          />
        ) : (
          <div className="p-40 text-3xl font-medium text-red-500 bg-black rounded-lg mt-7 w-full flex items-center justify-center">
            No Video
          </div>
        )}
      </div>
    </div>
  );
}
