const DataTable = ({ heads, items, className, tableHeight, isLoading }) => {
  return (
    <div className="w-full">
      <div className={`rounded w-full bg-white mt-4 p-3 ${className}`}>
        <div
          className={`w-full overflow-y-scroll scroll-container h-[calc(100vh-190px)] ${tableHeight}`}
        >
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-100 backdrop-blur-md sticky top-0 z-10 shadow-sm border-b-2 border-gray-300">
              <tr>
                {heads?.map((head, key) => (
                  <th key={key} scope="col" className="tableHead">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 h-full overflow-y-scroll">
              {items?.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  {Object.values(item).map((value, key) => (
                    <td className="py-2 px-3" key={key}>
                      <div className="text-sm whitespace-nowrap">
                        {value}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center py-10 w-full">
              <img src="/loading.gif" className="w-16" alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
