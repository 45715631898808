// mySlice.js

import { createSlice } from "@reduxjs/toolkit";
let isLoggedIn = localStorage.getItem("isLoggedIn");
const initialState = {
  name: "",
  password: "",
  isLoggedIn: isLoggedIn ? true : false,
};

const mySlice = createSlice({
  name: "mySlice",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    onLogin: (state, action) => {
      state.isLoggedIn = action.payload;
      localStorage.setItem("isLoggedIn", true);
    },
    onLogOut: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("isLoggedIn");
    },
  },
});

export const { setName, setPassword, onLogin, onLogOut } = mySlice.actions;

export default mySlice.reducer;
